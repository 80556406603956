<template>
  <div id="skills">
    <skills-add-new
      :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
      :skill-statuses="skillStatuses"
      @refetch-data="refetchData"
    />

    <skills-update
      :is-update-sidebar-active.sync="isUpdateSidebarActive"
      :skill="skill"
      @refetch-data="refetchData"
    />

    <b-card class="mb-0" body-class="p-0">
      <div class="m-2">
        <b-row>
          <b-col
            cols="6"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button variant="primary" @click="isAddNewSidebarActive = true">
                <span class="text-nowrap">Add New Role</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <!--
        Skills Table
       -->
      <b-table
        ref="refSkillsTable"
        class="position-relative"
        :items="fetchSkills"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="isLoading ? 'Loading...' : 'No matching records found'"
      >
        <template #cell(name)="data">
          {{ data.item.name }}
        </template>
        <template #cell(desc)="data">
          {{ data.item.desc }}
        </template>
        <template #cell(type)="data">
          {{ data.item.type }}
        </template>
        <!-- <template #cell(displayOrder)="data">
          <b-form-spinbutton
            v-model="data.item.displayOrder"
            @change="changeDisplayOrder(data.item._id, $event)"
          />
        </template>
        <template #cell(isActive)="data">
          <b-badge :variant="`light-${resolveSkillStatusVariant(data.item.isActive)}`">{{
            data.item.isActive ? "Active" : "Disabled"
          }}</b-badge>
        </template> -->

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button size="sm" variant="warning" @click="openSkillsUpdate(data.item._id)"
            >Edit</b-button
          >
          <b-button
            style="margin: 3px"
            size="sm"
            variant="primary"
            @click="showMsgBoxTwo(data.item._id)"
            >Delete</b-button
          >
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalSkills"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import { BCard, BRow, BCol, BFormInput, BButton, BTable, BPagination } from "bootstrap-vue";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import { mapActions } from "vuex";
import useSkillsList from "./useRolesList";

import SkillsAddNew from "./RolesAddNew.vue";
import SkillsUpdate from "./RolesUpdate.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    SkillsAddNew,
    SkillsUpdate,
    vSelect,
  },
  methods: {
    ...mapActions({
      deletRole: "profile/deleteRole",
    }),
    showMsgBoxTwo(id) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete this permission.", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deletRole(id);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
  },

  setup() {
    const isAddNewSidebarActive = ref(false);
    const skill = ref({});
    const {
      perPage,
      perPageOptions,
      searchQuery,
      fetchSkills,
      tableColumns,
      resolveSkillStatusVariant,
      refSkillsTable,
      totalSkills,
      currentPage,
      dataMeta,
      skillTypes,
      skillType,
      changeDisplayOrder,
      skillStatuses,
      refetchData,
      isUpdateSidebarActive,
      getSkillData,
      isLoading,
    } = useSkillsList();

    const openSkillsUpdate = async (skillID) => {
      const skillData = await getSkillData(skillID);
      skill.value = skillData;
      isUpdateSidebarActive.value = true;
    };
    return {
      perPage,
      perPageOptions,
      totalSkills,
      searchQuery,
      fetchSkills,
      tableColumns,
      resolveSkillStatusVariant,
      refSkillsTable,
      currentPage,
      dataMeta,
      skillTypes,
      skillType,
      changeDisplayOrder,
      isAddNewSidebarActive,
      isUpdateSidebarActive,
      skillStatuses,
      refetchData,
      openSkillsUpdate,
      skill,
      isLoading,
    };
  },
};
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
